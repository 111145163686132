import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Dashboard from '../pages/Admin/Dashboard';
import Administrators from '../pages/Admin/CTAStudents';
import Students from '../pages/Admin/ITCStudents';
import CTAApplicants from '../pages/Admin/CTAApplicants';
import Login from '../pages/Login';
import PrivateRoute from './PrivateRoute';
import InstructorDashboard from '../pages/instructor/Dashboard';
import InstructorCourses from '../pages/instructor/Courses';
import CourseDetails from '../pages/instructor/CourseDetails'
import StudentDashboard from '../pages/student/Dashboard';
import StudentCourses from '../pages/student/Courses';
import StudentCourseDetails from '../pages/student/CourseDetails';
import Resources from '../pages/student/Resources';
import CourseRegistration from '../pages/student/CourseRegistration';
import ITCApplicants from '../pages/Admin/ITCApplicant';
import CTAStudents from '../pages/Admin/CTAStudents';
import ITCStudents from '../pages/Admin/ITCStudents';
import UnreadEnquries from '../pages/Admin/UnreadEnquries';
import Messages from '../pages/Admin/Messages';
import JobApplicants from '../pages/Admin/JobApplicants';
import JobVacancies from '../pages/Admin/JobVacancies';
import Users from '../pages/Admin/Users';
const MainRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/itc_students" element={<ITCStudents />} />
          <Route path="/cta_students" element={<CTAStudents />} />
          <Route path="/cta_applicants" element={<CTAApplicants/>} />
          <Route path="/itc_applicants" element={<ITCApplicants />} />
          <Route path="/unread_enquiries" element={<UnreadEnquries />} />
          <Route path="/job_applicants" element={<JobApplicants />} />
          <Route path="/job_vacancies" element={<JobVacancies />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/users" element={<Users />} />
        </Route>
        <Route path="/instructorDashboard" element={<InstructorDashboard />} />
        <Route path="/instructorCourses" element={<InstructorCourses />} />
        <Route path="/courseDetails/:courseID" element={<CourseDetails />} />
        

        <Route path="/studentDashboard" element={<StudentDashboard />} />
        <Route path="/studentCourses" element={<StudentCourses/>} />
        <Route path="/student-course-details/:courseID" element={<StudentCourseDetails/>} />
        <Route path="/student-resources" element={<Resources/>} />
        <Route path="/course-registration" element={<CourseRegistration/>} />
        
      </Routes>
    </Router>
  );
};

export default MainRoutes;