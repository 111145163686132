import React from 'react'
import {NavLink} from 'react-router-dom'

import {BsBookHalf, BsFillGrid3X3GapFill, BsFillPatchQuestionFill, BsFillPeopleFill, BsPersonLinesFill} from 'react-icons/bs'
import {BiLogOut, BiSolidBook, BiSolidBookOpen} from 'react-icons/bi'
import {FaBell, FaFileSignature, FaFolderOpen, FaLeanpub, FaMoneyCheck, FaUserCog} from 'react-icons/fa'
import { FaMoneyCheckDollar } from 'react-icons/fa6'
import {GiTakeMyMoney} from 'react-icons/gi'
import { MdOutlineAssessment } from 'react-icons/md'
function SideNav() {
  return (
    <aside id="sidebar" className="sidebar">

    <ul className="sidebar-nav" id="sidebar-nav">

      <li className="nav-item">
      <NavLink exact to="/studentDashboard" className="nav-link " >
          <BsFillGrid3X3GapFill size={20} />
          <span style={{padding: '5px'}}>Dashboard</span>
        </NavLink>
      </li>
     
      <li className="nav-item">
      <NavLink exact to="/studentCourses" className="nav-link collapsed" >
          <BiSolidBookOpen size={20}/>
          <span style={{padding: '5px'}}>My Courses</span>
        </NavLink>
      </li>
     
      <li className="nav-item">
      <NavLink exact to="/student-resources" className="nav-link collapsed" >
          <FaFolderOpen size={20}/>
          <span style={{padding: '5px'}}>Resources</span>
        </NavLink>
      </li>
      <li className="nav-item">
      <NavLink exact to="/students" className="nav-link collapsed" >
          <FaFileSignature size={20}/>
          <span style={{padding: '5px'}}>Results</span>
        </NavLink>
      </li>
      <li className="nav-item">
      <NavLink exact to="/students" className="nav-link collapsed" >
          <MdOutlineAssessment size={20}/>
          <span style={{padding: '5px'}}>Assessment</span>
        </NavLink>
      </li>
      <li className="nav-item">
      <NavLink exact to="/course-registration" className="nav-link collapsed" >
          <FaLeanpub size={20}/>
          <span style={{padding: '5px'}}>Course Registration</span>
        </NavLink>
      </li>
      {/* <li className="nav-item">
      <NavLink exact to="/students" className="nav-link collapsed" >
          <FaMoneyCheckDollar size={20}/>
          <span style={{padding: '5px'}}>Financial Statement</span>
        </NavLink>
      </li>
      <li className="nav-item">
      <NavLink exact to="/students" className="nav-link collapsed" >
          <FaMoneyCheck size={20}/>
          <span style={{padding: '5px'}}>Generic Quotation</span>
        </NavLink>
      </li>

      <li className="nav-item">
      <NavLink exact to="/" className="nav-link collapsed" >
         <GiTakeMyMoney size={20} />
          <span style={{padding: '5px'}}>Online Payments</span>
        </NavLink>
      </li> */}

      <li className="nav-item">
        <a className="nav-link collapsed" >
          <BiLogOut size={20}/>
          <span style={{padding: '5px'}}>Logout</span>
        </a>
      </li>
    </ul>

  </aside>
  )
}

export default SideNav
