import React from 'react'
import SideNav from '../../components/SideNav'
import Applicants from '../../components/admin/JobApplicants'
import Header from '../../components/Header'
function JobApplicants() {
  return (
    <div>
        <Header/>
      <SideNav/>
      <Applicants/>
    </div>
  )
}

export default JobApplicants
