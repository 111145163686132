import { Outlet, Navigate } from 'react-router-dom';
import InstructorDashboard from '../pages/instructor/Dashboard';

const PrivateRoute = () => {
  const userRole = localStorage.getItem('userRole');

  if (userRole === 'admin') {
    return <Outlet />; 
  }

  // if (userRole === 'student') {
  //   return <StudentDashboard />;
  // }

  if (userRole === 'instructor') {
    return <InstructorDashboard />; 
  }

  return <Navigate to="/" />;
}

export default PrivateRoute;