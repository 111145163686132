import React from 'react'
import Header from '../../components/Header'
import SideNav from '../../components/student/Sidenav'
import CourseDetails from '../../components/student/CourseDetails'
function Courses() {
  return (
    <div>
    <Header/>
    <SideNav/>
    <CourseDetails/>
    </div>
  )
}

export default Courses
