import React from 'react'
import SideNav from '../../components/student/Sidenav'
import StudentResources from '../../components/student/Resources'
import Header from '../../components/Header'
function Resources() {
  return (
  <>
        <Header/>
        <SideNav/>
        <StudentResources/>
    </>
  )
}

export default Resources
