import React from 'react'
import Header from '../../components/Header'
import SideNav from '../../components/SideNav'
import ITCStudents from '../../components/admin/ITCStudents'
export default function Students() {
  return (
    <>
    <Header/>
    <SideNav/>
    <ITCStudents/>
    </>
  )
}

