import React from 'react'
import Header from '../../components/Header'
import SideNav from '../../components/SideNav'
import Enquiries from '../../components/admin/Enquiries'

function UnreadEnquries() {
  return (
    <div>
      <Header/>
      <SideNav/>
      <Enquiries/>
    </div>
  )
}

export default UnreadEnquries
