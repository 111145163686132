import React from 'react'
import InstructorCourses from '../../components/instructor/Courses'
import Header from '../../components/Header'
import SideNav from '../../components/instructor/SideNav'
function Courses() {
  return (
    <div>
    <Header/>
    <SideNav/>
    <InstructorCourses/>
    </div>
  )
}

export default Courses
