import React from 'react'
import Registration from '../../components/student/CourseRegistration'
import Header from '../../components/Header'
import SideNav from '../../components/student/Sidenav'
function Courses() {
  return (
    <div>
    <Header/>
    <SideNav/>
    <Registration/>
    </div>
  )
}

export default Courses
