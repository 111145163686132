import React from 'react'
import {NavLink} from 'react-router-dom'

import {BsBookHalf, BsFillGrid3X3GapFill, BsFillPatchQuestionFill, BsFillPeopleFill, BsPersonLinesFill} from 'react-icons/bs'
import {BiLogOut, BiSolidBook, BiSolidBookOpen} from 'react-icons/bi'
import {FaBell, FaUserCog} from 'react-icons/fa'
function SideNav() {
  return (
    <aside id="sidebar" className="sidebar">

    <ul className="sidebar-nav" id="sidebar-nav">

      <li className="nav-item">
      <NavLink exact to="/instructorDashboard" className="nav-link " >
          <BsFillGrid3X3GapFill size={20} />
          <span style={{padding: '5px'}}>Dashboard</span>
        </NavLink>
      </li>
     
      <li className="nav-item">
      <NavLink exact to="/instructorCourses" className="nav-link collapsed" >
          <BiSolidBookOpen size={20}/>
          <span style={{padding: '5px'}}> Courses</span>
        </NavLink>
      </li>

      <li className="nav-item">
      <NavLink exact to="/students" className="nav-link collapsed" >
          <BsPersonLinesFill size={20}/>
          <span style={{padding: '5px'}}>Assignments</span>
        </NavLink>
      </li>
{/* 
      <li className="nav-item">
      <NavLink exact to="/administrators" className="nav-link collapsed" >
         <FaUserCog size={20}/>
          <span style={{padding: '5px'}}>Tutorials</span>
        </NavLink>
      </li> */}
      <li className="nav-item">
      <NavLink exact to="/" className="nav-link collapsed" >
         <FaBell size={20} />
          <span style={{padding: '5px'}}>Quiz</span>
        </NavLink>
      </li>
      <li className="nav-item">
      <NavLink exact to="/instructors" className="nav-link collapsed" >
          <BsPersonLinesFill size={20}/>
          <span style={{padding: '5px'}}>Profile</span>
        </NavLink>
      </li>

      <li className="nav-item">
        <a className="nav-link collapsed" >
          <BiLogOut size={20}/>
          <span style={{padding: '5px'}}>Logout</span>
        </a>
      </li>
    </ul>

  </aside>
  )
}

export default SideNav
