import React from 'react';
import { NavLink } from 'react-router-dom';

import {
  BsBookHalf,
  BsFillGrid3X3GapFill,
  BsFillPatchQuestionFill,
  BsFillPeopleFill,
  BsPersonLinesFill,
} from 'react-icons/bs';
import { BiLogOut, BiSolidBook, BiSolidBookOpen } from 'react-icons/bi';
import { FaBell, FaUserCog } from 'react-icons/fa';

function LoginSideNav() {
  const handleLogout = () => {
    // Clear all storage
    localStorage.clear();

    // Redirect to the login page
    window.location.href = '/';
  };

  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        <li className="nav-item">
          <NavLink exact to="/dashboard" className="nav-link">
            <BsFillGrid3X3GapFill size={20}/>
            <span style={{ padding: '5px' }}>Dashboard</span>
          </NavLink>
        </li>
        {/* <li className="nav-item">
          <NavLink exact to="/cta_applicants" className="nav-link collapsed">
            <BsFillPeopleFill size={20} />
            <span style={{ padding: '5px' }}>CTA Applicants</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink exact to="/itc_applicants" className="nav-link collapsed">
            <BsPersonLinesFill size={20}/>
            <span style={{ padding: '5px' }}>ITC Applicants</span>
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink exact to="/itc_students" className="nav-link collapsed">
            <BiSolidBookOpen size={20}/>
            <span style={{ padding: '5px' }}> ITC Students</span>
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink exact to="/cta_students" className="nav-link collapsed">
            <FaUserCog size={20}/>
            <span style={{ padding: '5px' }}>CTA Students</span>
          </NavLink>
        </li> */}

        <li className="nav-item">
          <a className="nav-link collapsed" onClick={handleLogout}>
            <BiLogOut size={20} />
            <span style={{ padding: '5px' }}>Logout</span>
          </a>
        </li>
      </ul>
    </aside>
  );
}

export default LoginSideNav;