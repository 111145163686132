import React, { useState, useEffect } from 'react'
import {Modal, Checkbox, Form, Spin} from 'antd'
import Config from '../../config/config'
import axios from 'axios'
import MaterialTable from '@material-table/core'
import { FaEdit, FaTrash } from 'react-icons/fa'
import {BiSolidBookAdd} from 'react-icons/bi'
import {MdAssignmentAdd} from 'react-icons/md'
export default function Students() {
  const [studentsLoading, setStudentsLoading]=useState(false)
  const [courses,SetCourses]=useState([])
  const [enrollmentModal,setEnrollmentModal]= useState(false)
  const [enrollmentForm] = Form.useForm()
  const [levels, setLevel] = useState([])
  const config = Config()
  const userID = localStorage.userID
  useEffect(() => {
    getCourses()
    getLevel()
  }, [])

  const getLevel = () => {
    const url = ` ${config.base_url}level/`;
    axios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          setLevel(res.data);
        } else if (res.status === 401) {
          window.location.href = '/';
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          window.location.href = '/';
        }
      });
  };
  const getCourses = () => {
    setStudentsLoading(true)
    const url = ` ${config.base_url}student_course_enrollment/?student=${parseInt(userID)}`;
    axios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          setStudentsLoading(false)
          SetCourses(res.data.courses);
        } else if (res.status === 401) {
          setStudentsLoading(false)
          window.location.href = '/';
          const errorMessage = res.response?.data?.message ;
          return errorMessage;
        }
      })
      .catch((error) => {
        setStudentsLoading(false)
        if (error.response && error.response.status === 401) {
          window.location.href = '/';
          const errorMessage = error.response?.data?.message || 'Failed to load courses';
          return errorMessage;
        }
      });
  };

  async function enrollStudent(values) {
    setStudentsLoading(true)
    const selectedCourses = Object.entries(values.courses)
      .filter(([key, value]) => value)
      .map(([key, value]) => parseInt(key.replace('courses-', '')));

    const payload = {
      student: parseInt(userID),
      courses: selectedCourses,
    };
      const url = ` ${config.base_url}enroll_student/`;
    
      axios
      .post(url,payload)
      .then((res) => {
        if (res.status === 200) {
          setStudentsLoading(false)
         
        } else if (res.status === 401) {
          setStudentsLoading(false)
          window.location.href = '/';
          const errorMessage = res.response?.data?.message ;
          return errorMessage;
        }
      })
      .catch((error) => {
        setStudentsLoading(false)
        if (error.response && error.response.status === 401) {
          window.location.href = '/';
          const errorMessage = error.response?.data?.message || 'Failed to Add Student';
          return errorMessage;
        }
      });
  }

  return (
    <>
    <div className="pagetitle">
      <h1>Students</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/">Home</a></li>
          <li className="breadcrumb-item">Student</li>
          <li className="breadcrumb-item active">Course Registration</li>
        </ol>
      </nav>
    </div>
    <section class="section">
        <div class="row">
        <div class="col-lg-12">

            <div class="card">
            <div class="card-body">
                <h5 class="card-title"> Select Courses</h5>

                <Spin spinning={studentsLoading}>
                <Form
                    form={enrollmentForm}
                    name="enrollmentForm"
                    onFinish={(values) => {
                    // console.log(selectedCourses);
                    enrollStudent(values);
                    }}
                    initialValues={{ id: '', courses: {} }}
                    autoComplete="off"
                >
                    <Form.Item name='id' hidden={true} initialValue='' />
                    <div className="row ">
                    <div className="">
                        <div className="form-check">
                        {courses?.map(course => (
                            <Form.Item
                            name={['courses', course.id]}
                            initialValue={false} 
                            valuePropName="checked"
                            >
                            <Checkbox value={course.id}>
                                {course.name}
                            </Checkbox>
                            </Form.Item>
                        ))}
                        </div>
                    </div>
                    </div>
                    <div className="text-center">
                    <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </Form>
                </Spin>
            </div>
            </div>
        </div>
        </div>
    </section>
  </>
  )
}
