import React, { useState, useEffect } from 'react'
import {Modal, Form, Spin} from 'antd'
import Config from '../../config/config'
import axios from 'axios'
import MaterialTable from '@material-table/core'
import { FaEdit, FaTrash } from 'react-icons/fa';
import { BsBookHalf } from 'react-icons/bs'
import { Link } from 'react-router-dom';

export default function Courses() {
  const [addCourse, setAddCourse]= useState(false)
  const [courses, setCourses]= useState([])
  const [coursesLoading, setCoursesLoading]=useState(false)
  const [editCourseModal, setEditCourseModal]=useState(false)
  const [rowData, setRowData] = useState({})
  const [tutorialModal, setTutorialModal]=useState(false)
  const [tutorialFIle, setTutorialFile] = useState()
  const [addAssignmentModal, setAddAssignmentModal]=useState(false)
  const [videoFile, setVideoFile]=useState()
  const [attachmentFIle, setAttachmentsFIle]=useState([])
  const config = Config()
  const [addTutorialForm] = Form.useForm()
  const [addAssgignmentForm] = Form.useForm()
  
  useEffect(() => {
    getCourses()
  }, [])
  const userID = localStorage.userID

  const getCourses = () => {
    setCoursesLoading(true)
    const url = ` ${config.base_url}view_student_courses/${userID}`;
    axios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          setCoursesLoading(false)
          setCourses(res.data.courses);
        } else if (res.status === 401) {
          setCoursesLoading(false)
          window.location.href = '/';
          const errorMessage = res.response?.data?.message ;
          return errorMessage;
        }
      })
      .catch((error) => {
        setCoursesLoading(false)
        if (error.response && error.response.status === 401) {
          window.location.href = '/';
          const errorMessage = error.response?.data?.message || 'Failed to retrieve Courses';
          return errorMessage;
        }
      });
  };

  async function addTutorial(values) {
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('notes', tutorialFIle); 
    formData.append('video', videoFile);
    formData.append('instructor', parseInt(userID));
    formData.append('course', values.id);
  
    const url = `${config.base_url}add_tutorial/`;
  
    try {
      const response = await axios.post(url, formData);
  
      if (response.status === 200) {
        setAddAssignmentModal(false)
      } else if (response.status === 401) {
        window.location.href = '/';
        const errorMessage = response?.data?.message;
        return errorMessage;
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        window.location.href = '/';
        const errorMessage = error.response?.data?.message || 'Failed to Add Tutorial';
        return errorMessage;
      }
    } 
  }

  async function addAssignment(values) {
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('attachments', attachmentFIle); 
    formData.append('instructor', parseInt(userID));
    formData.append('course', values.id);
    formData.append('description', values.description);
    formData.append('dueDate', values.dueDate);
    formData.append('maxScore', values.maxScore);
  
    const url = `${config.base_url}add_assignment/`;
  
    try {
      const response = await axios.post(url, formData);
  
      if (response.status === 200) {
        setAddAssignmentModal(false)
      } else if (response.status === 401) {
        window.location.href = '/';
        const errorMessage = response?.data?.message;
        return errorMessage;
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        window.location.href = '/';
        const errorMessage = error.response?.data?.message || 'Failed to Add Assignment';
        return errorMessage;
      }
    }
  }

  const courseData = courses?.map((item) => {
    return {
      ...item,
    }
  })
  const getRandomColor = () => {
    const colors = ['green-gradient-bg-1', 'purple-gradient-bg-1', 'orange-gradient-bg-1', 'blue-gradient-bg-1'];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };
  return (
    <>
     <Modal
        style={{ marginTop: '-20px' }}
        visible={tutorialModal}
        footer={false}
        closable={true}
        onCancel={() => {
            setTutorialModal(false)
        }}
        width={900}
      >
        <section class="section">
          <div class="row">
            <div class="col-lg-12">

              <div class="card">
                <div class="card-body">
                  <h5 class="card-title"> Add Tutorial</h5>

                  {/* <Spin spinning={adminLoading}> */}
                    <Form
                      name="tutorialForm"
                      onFinish={addTutorial}
                      autoComplete="off"
                      form={addTutorialForm}
                    >
                      <Form.Item name='id' hidden={true} />
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Title</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name = 'title'
                          rules={[
                            {
                              required: true,
                              message: 'Enter Tutorial Title'
                            }
                          ]
                          }>
                            <input type="text" class="form-control" id="inputText"/>
                          </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputLastName" class="col-sm-2 col-form-label"> Tutorial Notes</label>
                      <div class="col-sm-10">
                      <Form.Item
                        name="notes"
                        rules={[
                          {
                            required: true,
                            message: 'Upload notes file!'
                          }
                        ]}
                      >
                        <input
                          type="file"
                          className="form-control"
                          id="inputNotes"
                          onChange={(e) => {
                            setTutorialFile(e.target.files[0])
                          }}
                        />
                      </Form.Item>
                      <span id="fileNameDisplay">No file selected</span>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Video</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'video'
                          >
                         <input
                          type="file"
                          className="form-control"
                          id="inputNotes"
                          onChange={(e) => {
                            setVideoFile(e.target.files[0])
                          }}
                        />
                      </Form.Item>
                      </div>
                    </div>
                    <div class="text-center">
                      <button type="submit" class="btn btn-primary">Submit</button>
                    </div>
                  </Form>
                  {/* </Spin> */}
                </div>
              </div>

            </div>
          </div>
        </section>
      </Modal>
      <Modal
        style={{ marginTop: '-20px' }}
        visible={addAssignmentModal}
        footer={false}
        closable={true}
        onCancel={() => {
            setAddAssignmentModal(false)
        }}
        width={900}
      >
        <section class="section">
          <div class="row">
            <div class="col-lg-12">

              <div class="card">
                <div class="card-body">
                  <h5 class="card-title"> Add Assignment</h5>

                  {/* <Spin spinning={adminLoading}> */}
                    <Form
                      name="tutorialForm"
                      onFinish={addAssignment}
                      autoComplete="off"
                      form={addAssgignmentForm}
                    >
                      <Form.Item name='id' hidden={true} />
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Title</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name = 'title'
                          rules={[
                            {
                              required: true,
                              message: 'Enter Assigment Title'
                            }
                          ]
                          }>
                            <input type="text" class="form-control" id="inputText"/>
                          </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Description</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name = 'description'
                          rules={[
                            {
                              required: true,
                              message: 'Enter Assignment Description'
                            }
                          ]
                          }>
                            <input type="text" class="form-control" id="inputText"/>
                          </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Due Date</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name = 'dueDate'
                          rules={[
                            {
                              required: true,
                              message: 'Select Due Date'
                            }
                          ]
                          }>
                            <input type="date" class="form-control" id="inputText"/>
                          </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Max Score</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name = 'maxScore'
                          rules={[
                            {
                              required: true,
                              message: 'Enter Maximum Score'
                            }
                          ]
                          }>
                            <input type="number" class="form-control" id="inputText"/>
                          </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputLastName" class="col-sm-2 col-form-label"> Attachments</label>
                      <div class="col-sm-10">
                      <Form.Item
                        name="attachments"
                  
                      >
                        <input
                          type="file"
                          className="form-control"
                          id="inputNotes"
                          onChange={(e) => {
                            setAttachmentsFIle(e.target.files[0])
                          }}
                        />
                      </Form.Item>
                      </div>
                    </div>
                    <div class="text-center">
                      <button type="submit" class="btn btn-primary">Submit</button>
                    </div>
                  </Form>
                  {/* </Spin> */}
                </div>
              </div>

            </div>
          </div>
        </section>
      </Modal>
    <div className="pagetitle">
      <h1>Course</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/">Home</a></li>
          <li className="breadcrumb-item">Instructor</li>
          <li className="breadcrumb-item active">Courses</li>
        </ol>
      </nav>
    </div>
    
    <div className="row">
    {courses?.map(course => (
    <div key={course.id} className="col-12 col-md-6 col-lg-3 mt-4">
      <Link to={`/student-course-details/${course.id}`}>
        <div className="card box-shadow-1 border-0 rounded-0">
          <div className="card-body">
            <h6 className="text-uppercase fw-800 fs-12px text-green-3">
            
            </h6>
            <span className="text-green ms-2 fw-600">{course.name}</span>

            <a
              href="#"
              className={`btn btn-sm w-100 border-0 rounded-pill text-white fw-800 mt-2 fs-12px ${getRandomColor()}`}
            >
              View
            </a>
          </div>
        </div>
      </Link>
    </div>
    ))}
    </div>
    
    {/* <section className="section">
      <div className="">
          <div className="card border-0 p-0 rounded-0 box-shadow-1">
              <div className="card-header border-0 blue-gradient-bg-2 d-flex justify-content-between align-items-center px-4">
                  <>
                    <h4 className="text-white fw-700 fs-16px m-0">
                    Courses
                    </h4>
                  </>
              </div>
              <div className="card-body px-0 pt-2 pb-4">
                <div className="table-responsive">
                <MaterialTable
                  title=""
                  columns={[
                    { title: 'Course Name', field: 'name' },                    
                  ]}
                  actions={[
                    {
                      icon: () => <FaEdit />,
                      tooltip: 'Add Tutorial',
                      onClick: (event, rowData) => {
                        setRowData(rowData)
                        addTutorialForm.setFieldsValue({
                          ...rowData,
                        })
                        setTutorialModal(true)
                      },
                    },
                    {
                      icon: () => <BsBookHalf />,
                      tooltip: 'Add Assignment',
                      onClick: (event, rowData) => {
                        setRowData(rowData)
                        addAssgignmentForm.setFieldsValue({
                          ...rowData,
                        })
                        setAddAssignmentModal(true)
                      },
                    },
                    
                  ]}
                  // isLoading={isCustomerOrderDetailsLoading}
                  data={courseData}
                />
                </div>
              </div>
            </div>
          </div>
    </section> */}
   
  </>
  )
}
