import React from 'react'
import UserLogin from '../components/Login'
import Header from '../components/Header'
import LoginSideNav from '../components/LoginSidenav'
function Login() {
  return (
    <>
    <Header/>
    <LoginSideNav/>
    <UserLogin/>
    </>
  )
}

export default Login
