import React, { useState, useEffect } from 'react'
import {Modal, Form, Spin, Button, Tooltip} from 'antd'
import Config from '../../config/config'
import axios from 'axios'
import MaterialTable from '@material-table/core'
import { FaEdit, FaFolderOpen, FaTrash } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md'
import { useParams } from 'react-router-dom';
import { BsBookHalf, BsFillPeopleFill } from 'react-icons/bs'
import {BiBookReader, BiSolidBookContent} from 'react-icons/bi'
import { FaPeopleRoof } from 'react-icons/fa6';
import {PiExamBold, PiExamFill} from 'react-icons/pi'
export default function CourseDetails() {
  const [addCourse, setAddCourse]= useState(false)
  const [courses, setCourses]= useState([])
  const [coursesLoading, setCoursesLoading]=useState(false)
  const [editCourseModal, setEditCourseModal]=useState(false)
  const [rowData, setRowData] = useState({})
  const [tutorialModal, setTutorialModal]=useState(false)
  const [tutorialFIle, setTutorialFile] = useState()
  const [addAssignmentModal, setAddAssignmentModal]=useState(false)
  const [videoFile, setVideoFile]=useState()
  const [resourceFile, setResourceFile]=useState()
  const [attachmentFIle, setAttachmentsFIle]=useState([])
  const config = Config()
  const [addTutorialForm] = Form.useForm()
  const [addAssgignmentForm] = Form.useForm()
  const [courseDetails, setCourseDetails]= useState([])
  const [levels, setLevel] = useState([])
  const [resourcesModal, setResourcesModal]=useState(false)
  const { courseID } = useParams();
  const [category, setCategory]=useState([])
  useEffect(() => {
    getCourses()
    getLevel()
    getCategory()
  }, [])
  const userID = localStorage.userID
  const getCourses = () => {
    setCoursesLoading(true)
    const url = ` ${config.base_url}course_details/?courseID=${parseInt(courseID)}&instructorID=${parseInt(userID)}`;
    axios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          setCoursesLoading(false)
          setCourses(res.data);
          setCourseDetails(res.data.courseDetails)
          console.log(courseDetails)
        } else if (res.status === 401) {
          setCoursesLoading(false)
          window.location.href = '/';
          const errorMessage = res.response?.data?.message ;
          return errorMessage;
        }
      })
      .catch((error) => {
        setCoursesLoading(false)
        if (error.response && error.response.status === 401) {
          window.location.href = '/';
          const errorMessage = error.response?.data?.message || 'Failed to retrieve Courses';
          return errorMessage;
        }
      });
  };
  const getLevel = () => {
    const url = ` ${config.base_url}level/`;
    axios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          setLevel(res.data);
        } else if (res.status === 401) {
          window.location.href = '/';
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          window.location.href = '/';
        }
      });
  };
  const getCategory = () => {
    const url = ` ${config.base_url}category/`;
    axios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          setCategory(res.data);
        } else if (res.status === 401) {
          window.location.href = '/';
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          window.location.href = '/';
        }
      });
  };
  async function addTutorial(values) {
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('notes', tutorialFIle); 
    formData.append('video', videoFile);
    formData.append('instructor', parseInt(userID));
    formData.append('course', parseInt(courseID));
  
    const url = `${config.base_url}add_tutorial/`;
  
    try {
      const response = await axios.post(url, formData);
  
      if (response.status === 200) {
        setAddAssignmentModal(false)
      } else if (response.status === 401) {
        window.location.href = '/';
        const errorMessage = response?.data?.message;
        return errorMessage;
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        window.location.href = '/';
        const errorMessage = error.response?.data?.message || 'Failed to Add Tutorial';
        return errorMessage;
      }
    } 
  }
  async function addResources(values) {
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('attachment', resourceFile); 
    formData.append('level', values.level);
    formData.append('course', courseID);
    formData.append('category', values.category);
  
    const url = `${config.base_url}add_resources/`;
  
    try {
      const response = await axios.post(url, formData);
  
      if (response.status === 200) {
        setAddAssignmentModal(false)
      } else if (response.status === 401) {
        window.location.href = '/';
        const errorMessage = response?.data?.message;
        return errorMessage;
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        window.location.href = '/';
        const errorMessage = error.response?.data?.message || 'Failed to Add Tutorial';
        return errorMessage;
      }
    } 
  }

  async function addAssignment(values) {
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('attachments', attachmentFIle); 
    formData.append('instructor', parseInt(userID));
    formData.append('course', courseID);
    formData.append('description', values.description);
    formData.append('dueDate', values.dueDate);
    formData.append('maxScore', values.maxScore);
  
    const url = `${config.base_url}add_assignment/`;
  
    try {
      const response = await axios.post(url, formData);
  
      if (response.status === 200) {
        setAddAssignmentModal(false)
      } else if (response.status === 401) {
        window.location.href = '/';
        const errorMessage = response?.data?.message;
        return errorMessage;
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        window.location.href = '/';
        const errorMessage = error.response?.data?.message || 'Failed to Add Assignment';
        return errorMessage;
      }
    }
  }

  const getRandomColor = () => {
    const colors = ['#641E16', '#512E5F', '#145A32', '#D4AC0D', '#FFBF00', '#FF0000', '#0000FF', '#008080', '#800080', '#808080', '#438D80', '#4E8975', '#FF7F50','#00FFFF', '#D35400'];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };
  const courseData = courses.tutorials?.map((item) => {
    return {
      ...item,
    }
  })
  return (
    <>
     <Modal
        style={{ marginTop: '-20px' }}
        visible={tutorialModal}
        footer={false}
        closable={true}
        onCancel={() => {
            setTutorialModal(false)
        }}
        width={900}
      >
        <section class="section">
          <div class="row">
            <div class="col-lg-12">

              <div class="card">
                <div class="card-body">
                  <h5 class="card-title"> Add Tutorial</h5>

                  {/* <Spin spinning={adminLoading}> */}
                    <Form
                      name="tutorialForm"
                      onFinish={addTutorial}
                      autoComplete="off"
                      form={addTutorialForm}
                    >
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Title</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name = 'title'
                          rules={[
                            {
                              required: true,
                              message: 'Enter Tutorial Title'
                            }
                          ]
                          }>
                            <input type="text" class="form-control" id="inputText"/>
                          </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputLastName" class="col-sm-2 col-form-label"> Tutorial Notes</label>
                      <div class="col-sm-10">
                      <Form.Item
                        name="notes"
                        rules={[
                          {
                            required: true,
                            message: 'Upload notes file!'
                          }
                        ]}
                      >
                        <input
                          type="file"
                          className="form-control"
                          id="inputNotes"
                          onChange={(e) => {
                            setTutorialFile(e.target.files[0])
                          }}
                        />
                      </Form.Item>
                      <span id="fileNameDisplay">No file selected</span>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Video</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'video'
                          >
                         <input
                          type="file"
                          className="form-control"
                          id="inputNotes"
                          onChange={(e) => {
                            setVideoFile(e.target.files[0])
                          }}
                        />
                      </Form.Item>
                      </div>
                    </div>
                    <div class="text-center">
                      <button type="submit" class="btn btn-primary">Submit</button>
                    </div>
                  </Form>
                  {/* </Spin> */}
                </div>
              </div>

            </div>
          </div>
        </section>
      </Modal>
      <Modal
        style={{ marginTop: '-20px' }}
        visible={resourcesModal}
        footer={false}
        closable={true}
        onCancel={() => {
            setResourcesModal(false)
        }}
        width={900}
      >
        <section class="section">
          <div class="row">
            <div class="col-lg-12">

              <div class="card">
                <div class="card-body">
                  <h5 class="card-title"> Add Resources</h5>

                  {/* <Spin spinning={adminLoading}> */}
                    <Form
                      name="tutorialForm"
                      onFinish={addResources}
                      autoComplete="off"
                      
                    >
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Title</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name = 'title'
                          rules={[
                            {
                              required: true,
                              message: 'Enter Tutorial Title'
                            }
                          ]
                          }>
                            <input type="text" class="form-control" id="inputText"/>
                          </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Level</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name="level"
                          rules={[
                            {
                              required: true,
                              message: 'Select Level!',
                            },
                          ]}
                        >
                          <select
                            // loading={isSupervisorsLoading}
                            className="form-control"
                          >
                            <option value="">Select Level</option>
                            {levels?.map((name) => {
                              return (
                                <option value={name.id}>
                                  {name.title}
                                </option>
                              );
                            })}
                          </select>
                        </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Category</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name="category"
                          rules={[
                            {
                              required: true,
                              message: 'Select Category!',
                            },
                          ]}
                        >
                          <select
                            // loading={isSupervisorsLoading}
                            className="form-control"
                          >
                            <option value="">Select Category</option>
                            {category?.map((name) => {
                              return (
                                <option value={name.id}>
                                  {name.name}
                                </option>
                              );
                            })}
                          </select>
                        </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">File</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'attachment'
                          >
                         <input
                          type="file"
                          className="form-control"
                          id="inputNotes"
                          onChange={(e) => {
                            setResourceFile(e.target.attachment[0])
                          }}
                        />
                      </Form.Item>
                      </div>
                    </div>
                    <div class="text-center">
                      <button type="submit" class="btn btn-primary">Submit</button>
                    </div>
                  </Form>
                  {/* </Spin> */}
                </div>
              </div>

            </div>
          </div>
        </section>
      </Modal>
      <Modal
        style={{ marginTop: '-20px' }}
        visible={addAssignmentModal}
        footer={false}
        closable={true}
        onCancel={() => {
            setAddAssignmentModal(false)
        }}
        width={900}
      >
        <section class="section">
          <div class="row">
            <div class="col-lg-12">

              <div class="card">
                <div class="card-body">
                  <h5 class="card-title"> Add Assignment</h5>

                  {/* <Spin spinning={adminLoading}> */}
                    <Form
                      name="tutorialForm"
                      onFinish={addAssignment}
                      autoComplete="off"
                      form={addAssgignmentForm}
                    >
                    
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Title</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name = 'title'
                          rules={[
                            {
                              required: true,
                              message: 'Enter Assigment Title'
                            }
                          ]
                          }>
                            <input type="text" class="form-control" id="inputText"/>
                          </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Description</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name="description"
                          rules={[
                            {
                              required: true,
                              message: 'Enter Assignment Description'
                            }
                          ]}
                        >
                          <textarea class="form-control" id="inputText"></textarea>
                        </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Due Date</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name = 'dueDate'
                          rules={[
                            {
                              required: true,
                              message: 'Select Due Date'
                            }
                          ]
                          }>
                            <input type="date" class="form-control" id="inputText"/>
                          </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Max Score</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name = 'maxScore'
                          rules={[
                            {
                              required: true,
                              message: 'Enter Maximum Score'
                            }
                          ]
                          }>
                            <input type="number" class="form-control" id="inputText"/>
                          </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputLastName" class="col-sm-2 col-form-label"> Attachments</label>
                      <div class="col-sm-10">
                      <Form.Item
                        name="attachments"
                  
                      >
                        <input
                          type="file"
                          className="form-control"
                          id="inputNotes"
                          onChange={(e) => {
                            setAttachmentsFIle(e.target.files[0])
                          }}
                        />
                      </Form.Item>
                      </div>
                    </div>
                    <div class="text-center">
                      <button type="submit" class="btn btn-primary">Submit</button>
                    </div>
                  </Form>
                  {/* </Spin> */}
                </div>
              </div>

            </div>
          </div>
        </section>
      </Modal>
    <div className="pagetitle">
      <h1>Course</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/">Home</a></li>
          <li className="breadcrumb-item">Instructor</li>
          <li className="breadcrumb-item active">Courses</li>
          <li className="breadcrumb-item active">{courseDetails?.map(course =>(course.name))}</li>
        </ol>
      </nav>
    </div>
    <section className="section dashboard">
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-xxl-3 col-md-6">
            <div className="card info-card sales-card">
  
              <div className="card-body">
                <h5 className="card-title">Students <span></span></h5>
                <div className="d-flex align-items-center">
                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <FaPeopleRoof/>
                  </div>
                  <div className="ps-3">
                    <h6>{courses.studentCount}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xxl-3 col-md-6">
            <div className="card info-card revenue-card">
              <div className="card-body">
                <h5 className="card-title">Assignments </h5>

                <div className="d-flex align-items-center">
                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <BsBookHalf/>
                  </div>
                  <div className="ps-3">
                    <h6>{courses.assignmentCount}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-3 col-md-6">
            <div className="card info-card revenue-card">
              <div className="card-body">
                <h5 className="card-title">Tutorials </h5>

                <div className="d-flex align-items-center">
                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <BiBookReader/>
                  </div>
                  <div className="ps-3">
                    <h6>{courses.tutorialsCount}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xxl-3 col-md-6">
            <div className="card info-card customers-card">
              <div className="card-body">
                <h5 className="card-title">Assignments Due </h5>

                <div className="d-flex align-items-center">
                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                   <BiSolidBookContent/>
                  </div>
                  <div className="ps-3">
                    <h6>{courses.assignmentCount}</h6>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div className="pb-4  d-flex justify-content-end">
    <Tooltip
        title={`Add Tutorial`}
      >
      <a
        href
        onClick={() => {
          setTutorialModal(true)
        }}
        className="ms-3 btn btn-circle-lg orange-gradient-bg-1 text-white fw-800 border-0 rounded-3 box-shadow-btn"
      >
          <BiBookReader /> 
      </a>
    </Tooltip>
    <Tooltip
        title={`Add Assignment`}
      >
      <a
        href
        onClick={() => {
          setAddAssignmentModal(true)
        }}
        className="ms-3 btn btn-circle-lg orange-gradient-bg-1 text-white fw-800 border-0 rounded-3 box-shadow-btn"
      >
          <BiSolidBookContent /> 
      </a>
    </Tooltip>
    <Tooltip
        title={`Add Quiz`}
      >
      <a
        href
        onClick={() => {
          setTutorialModal(true)
        }}
        className="ms-3 btn btn-circle-lg orange-gradient-bg-1 text-white fw-800 border-0 rounded-3 box-shadow-btn"
      >
          <PiExamBold /> 
      </a>
    </Tooltip>
    <Tooltip
        title={`Add Exam`}
      >
      <a
        href
        onClick={() => {
          setTutorialModal(true)
        }}
        className="ms-3 btn btn-circle-lg orange-gradient-bg-1 text-white fw-800 border-0 rounded-3 box-shadow-btn"
      >
          <PiExamFill /> 
      </a>
    </Tooltip>
    <Tooltip
        title={`Add Resources`}
      >
      <a
        href
        onClick={() => {
          setResourcesModal(true)
        }}
        className="ms-3 btn btn-circle-lg orange-gradient-bg-1 text-white fw-800 border-0 rounded-3 box-shadow-btn"
      >
          <FaFolderOpen /> 
      </a>
    </Tooltip>
    
  </div>
  <section className="section">
      <div className="">
          <div className="card border-0 p-0 rounded-0 box-shadow-1">
              <div className="card-header border-0 blue-gradient-bg-2 d-flex justify-content-between align-items-center px-4">
                  <>
                    <h4 className="text-white fw-700 fs-16px m-0">
                    {courseDetails?.map(course =>(course.name))} Tutorials
                    </h4>
                  </>
              </div>
              <div className="card-body px-0 pt-2 pb-4">
                <div className="table-responsive">
                <MaterialTable
                  title=""
                  columns={[
                    { title: ' Title', field: 'title' },  
                    {
                      title: 'Date Created',
                      field: 'created_at',
                      render: rowData => {
                        const date = new Date(rowData.created_at);
                        const day = date.getDate().toString().padStart(2, '0');
                        const month = (date.getMonth() + 1).toString().padStart(2, '0');
                        const year = date.getFullYear();
                        return `${day}-${month}-${year}`;
                      }
                    },
                    {
                      title: 'View Notes',
                      render: rowData => (
                        <a
                          href={`${config.media_url}${rowData.notes}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn float-right blue-gradient-bg-1 rounded-2 text-white fw-800 border-0 px-4"
                        >
                          View Notes
                        </a>
                      ),
                    },
                    {
                      title: 'View Video',
                      render: rowData => (
                        <a
                          href={`${config.media_url}${rowData.video}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn float-right blue-gradient-bg-1 rounded-2 text-white fw-800 border-0 px-4"
                        >
                          View Video
                        </a>
                      )
                    }            
                  ]}
                  actions={[
                    {
                      icon: () => <FaEdit />,
                      tooltip: 'Edit Tutorial',
                      onClick: (event, rowData) => {
                        setRowData(rowData)
                        addTutorialForm.setFieldsValue({
                          ...rowData,
                        })
                        setTutorialModal(true)
                      },
                    },
                    {
                      icon: () => <MdDelete />,
                      tooltip: 'Delete Tutorial',
                      onClick: (event, rowData) => {
                        
                        setAddAssignmentModal(true)
                      },
                    },
                    
                  ]}
                  // isLoading={isCustomerOrderDetailsLoading}
                  data={courseData}
                />
                </div>
              </div>
            </div>
          </div>
    </section>
  </>
  )
}
