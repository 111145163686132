import React from 'react'
import SideNav from '../../components/instructor/SideNav'
import InstructorDashboard from '../../components/instructor/Dashboard'
import Header from '../../components/Header'
function Dashboard() {
  return (
  <>
        <Header/>
        <SideNav/>
        <InstructorDashboard/>
    </>
  )
}

export default Dashboard
