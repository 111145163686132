import React, { useState, useEffect } from 'react'
import {Modal, Form, Spin, Button, Tooltip} from 'antd'
import Config from '../../config/config'
import axios from 'axios'
import MaterialTable from '@material-table/core'
import { FaEdit, FaTrash } from 'react-icons/fa';
import { MdDelete, MdOutlineQuiz } from 'react-icons/md'
import { useParams } from 'react-router-dom';
import { BsBookHalf, BsFillPeopleFill } from 'react-icons/bs'
import {BiBookReader, BiCheckboxChecked, BiSolidBookContent} from 'react-icons/bi'
import { FaFolderOpen, FaPeopleRoof } from 'react-icons/fa6';
import {PiExamBold, PiExamDuotone, PiExamFill} from 'react-icons/pi'
import { GiDiscussion } from 'react-icons/gi'
import {GoCommentDiscussion} from 'react-icons/go'
export default function CourseDetails() {
  const [addCourse, setAddCourse]= useState(false)
  const [courses, setCourses]= useState([])
  const [coursesLoading, setCoursesLoading]=useState(false)
  const [editCourseModal, setEditCourseModal]=useState(false)
  const [rowData, setRowData] = useState({})
  const [tutorialModal, setTutorialModal]=useState(false)
  const [tutorialFIle, setTutorialFile] = useState([])
  const [addAssignmentModal, setAddAssignmentModal]=useState(false)
  const [viewAssignmentsModal, setViewAssignmentsModal]=useState(false)
  const [videoFile, setVideoFile]=useState()
  const [attachmentFIle, setAttachmentsFIle]=useState([])
  const config = Config()
  const [addTutorialForm] = Form.useForm()
  const [addAssgignmentForm] = Form.useForm()
  const [courseDetails, setCourseDetails]= useState([])
  const { courseID } = useParams();
  const [tutorialNotesModal, setTutorialNotesModal] = useState(false);
  const [selectedTutorial, setSelectedTutorial] = useState(null);
  useEffect(() => {
    getCourses()
  }, [])
  const userID = localStorage.userID
  const getCourses = () => {
    setCoursesLoading(true)
    const url = ` ${config.base_url}student_course_details/?courseID=${parseInt(courseID)}&studentID=${parseInt(userID)}`;
    axios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          setCoursesLoading(false)
          setCourses(res.data);
          setCourseDetails(res.data.courseDetails)
          console.log(courseDetails)
        } else if (res.status === 401) {
          setCoursesLoading(false)
          window.location.href = '/';
          const errorMessage = res.response?.data?.message ;
          return errorMessage;
        }
      })
      .catch((error) => {
        setCoursesLoading(false)
        if (error.response && error.response.status === 401) {
          window.location.href = '/';
          const errorMessage = error.response?.data?.message || 'Failed to retrieve Courses';
          return errorMessage;
        }
      });
  };

  const courseData = courses.tutorials?.map((item) => {
    return {
      ...item,
    }
  })
  return (
    <>
    <Modal
        style={{ marginTop: '-20px' }}
        visible={addAssignmentModal}
        footer={false}
        closable={true}
        onCancel={() => {
            setAddAssignmentModal(false)
        }}
        width={900}
      >
        <section class="section">
          <div class="row">
            <div class="col-lg-12">

              <div class="card">
                <div class="card-body">
                  <h5 class="card-title"> SUBMIT ASSIGNMENT</h5>

                  {/* <Spin spinning={adminLoading}> */}
                    <Form
                      name="tutorialForm"
                    //   onFinish={addAssignment}
                      autoComplete="off"
                      form={addAssgignmentForm}
                    >
                    
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Title</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name = 'title'
                          rules={[
                            {
                              required: true,
                              message: 'Enter Assigment Title'
                            }
                          ]
                          }>
                            <input type="text" class="form-control" id="inputText"/>
                          </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Description</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name="description"
                          rules={[
                            {
                              required: true,
                              message: 'Enter Assignment Description'
                            }
                          ]}
                        >
                          <textarea class="form-control" id="inputText"></textarea>
                        </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Due Date</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name = 'dueDate'
                          rules={[
                            {
                              required: true,
                              message: 'Select Due Date'
                            }
                          ]
                          }>
                            <input type="date" class="form-control" id="inputText"/>
                          </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Max Score</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name = 'maxScore'
                          rules={[
                            {
                              required: true,
                              message: 'Enter Maximum Score'
                            }
                          ]
                          }>
                            <input type="number" class="form-control" id="inputText"/>
                          </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputLastName" class="col-sm-2 col-form-label"> Attachments</label>
                      <div class="col-sm-10">
                      <Form.Item
                        name="attachments"
                  
                      >
                        <input
                          type="file"
                          className="form-control"
                          id="inputNotes"
                          onChange={(e) => {
                            setAttachmentsFIle(e.target.files[0])
                          }}
                        />
                      </Form.Item>
                      </div>
                    </div>
                    <div class="text-center">
                      <button type="submit" class="btn btn-primary">Submit</button>
                    </div>
                  </Form>
                  {/* </Spin> */}
                </div>
              </div>

            </div>
          </div>
        </section>
      </Modal>
      <Modal
        style={{ marginTop: '-20px' }}
        visible={viewAssignmentsModal}
        footer={false}
        closable={true}
        onCancel={() => {
            setViewAssignmentsModal(false)
        }}
        width={900}
      >
        <section class="section">
          <div class="row">
            <div class="col-lg-12">

              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">ASSIGNMENTS DUE</h5>

                  {/* <Spin spinning={adminLoading}> */}
                  <MaterialTable
                    title=""
                    columns={[
                        { title: ' Title', field: 'title' },  
                        { title: ' Description', field: 'description' },  
                        {
                        title: 'Date Created',
                        field: 'dueDate',
                        render: rowData => {
                            const date = new Date(rowData.created_at);
                            const day = date.getDate().toString().padStart(2, '0');
                            const month = (date.getMonth() + 1).toString().padStart(2, '0');
                            const year = date.getFullYear();
                            return `${day}-${month}-${year}`;
                        }
                        },
                        {
                        title: 'View File',
                        render: rowData => (
                            <a
                            href={`${config.media_url}${rowData.notes}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn float-right blue-gradient-bg-1 rounded-2 text-white fw-800 border-0 px-4"
                            >
                            View Notes
                            </a>
                        ),
                        }
                    ]}
                    actions={[
                        {
                        icon: () => <BiCheckboxChecked />,
                        tooltip: 'Mark as Done',
                        //   onClick: (event, rowData) => {
                        //     setRowData(rowData)
                        //     setTutorialFile(rowData.notes)
                        //     setTutorialModal(true)
                        //   },
                        },
                        
                    ]}
                    // isLoading={isCustomerOrderDetailsLoading}
                    data={courseData}
                    />
                  {/* </Spin> */}
                </div>
              </div>

            </div>
          </div>
        </section>
      </Modal>
    <Modal
        style={{ marginTop: '-20px' }}
        visible={tutorialModal}
        footer={false}
        closable={true}
        onCancel={() => {
            setTutorialModal(false)
        }}
        width={1400}
        >
        <section class="section">
            <iframe
                src={`${config.media_url}${tutorialFIle}#toolbar=0&navpanes=0&scrollbar=0`}
                frameBorder="0"
                scrolling="auto"
                height="500px"
                width="100%"
            ></iframe>
        </section>
    </Modal>
    <div className="pagetitle">
      <h1>Course</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/">Home</a></li>
          <li className="breadcrumb-item">Student</li>
          <li className="breadcrumb-item active">Courses</li>
          <li className="breadcrumb-item active">{courseDetails?.map(course =>(course.name))}</li>
        </ol>
      </nav>
    </div>
    <section className="section dashboard">
    <div className="row">
      <div className="col-lg-12">
        <div className="row">

          <div className="col-xxl-2 col-6 col-md-4">
            <div className="card info-card revenue-card">
              <div className="card-body">
                <h5 className="card-title">Assignments Due </h5>

                <div className="d-flex align-items-center">
                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <BsBookHalf/>
                  </div>
                  <div className="ps-3">
                    <h6>{courses.assignmentCount || 0}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-2 col-6 col-md-4">
            <div className="card info-card customers-card">
              <div className="card-body">
                <h5 className="card-title">Tutorials </h5>

                <div className="d-flex align-items-center">
                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <BiBookReader/>
                  </div>
                  <div className="ps-3">
                    <h6>{courses.tutorialsCount || 0}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-2 col-6 col-md-4">
            <div className="card info-card revenue-card">
              <div className="card-body">
                <h5 className="card-title">Discussions </h5>

                <div className="d-flex align-items-center">
                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <GiDiscussion/>
                  </div>
                  <div className="ps-3">
                    <h6>{courses.tutorialsCount || 0}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-2  col-6 col-md-4">
            <div className="card info-card customers-card">
              <div className="card-body">
                <h5 className="card-title">Quiz </h5>

                <div className="d-flex align-items-center">
                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <MdOutlineQuiz/>
                  </div>
                  <div className="ps-3">
                    <h6>{courses.tutorialsCount || 0}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-2 col-6 col-md-4">
            <div className="card info-card revenue-card">
              <div className="card-body">
                <h5 className="card-title">Exams </h5>

                <div className="d-flex align-items-center">
                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <PiExamDuotone/>
                  </div>
                  <div className="ps-3">
                    <h6>{courses.tutorialsCount || 0}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-2  col-6 col-md-4">
            <div className="card info-card customers-card">
              <div className="card-body">
                <h5 className="card-title">Resources </h5>

                <div className="d-flex align-items-center">
                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                   <FaFolderOpen/>
                  </div>
                  <div className="ps-3">
                    <h6>{courses.assignmentCount|| 0}</h6>
                  </div>
                </div>

              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </section>
  <section className="section">
      <div className="">
          <div className="card border-0 p-0 rounded-0 box-shadow-1">
              <div className="card-header border-0 blue-gradient-bg-2 d-flex justify-content-between align-items-center px-4">
                  <>
                    <h4 className="text-white fw-700 fs-16px m-0">
                    {courseDetails?.map(course =>(course.name))} Tutorials
                    </h4>
                  </>
              </div>
              <div className="card-body px-0 pt-2 pb-4">
                <div className="table-responsive">
                <MaterialTable
                  title=""
                  columns={[
                    { title: ' Title', field: 'title' },  
                    {
                      title: 'Date Created',
                      field: 'created_at',
                      render: rowData => {
                        const date = new Date(rowData.created_at);
                        const day = date.getDate().toString().padStart(2, '0');
                        const month = (date.getMonth() + 1).toString().padStart(2, '0');
                        const year = date.getFullYear();
                        return `${day}-${month}-${year}`;
                      }
                    },
                    {
                      title: 'View Notes',
                      render: rowData => (
                        <a
                          href={`${config.media_url}${rowData.notes}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn float-right blue-gradient-bg-1 rounded-2 text-white fw-800 border-0 px-4"
                        >
                          View Notes
                        </a>
                      ),
                    },
                    {
                        title: 'View Video',
                        render: rowData => (
                          <button
                            className="btn float-right blue-gradient-bg-1 rounded-2 text-white fw-800 border-0 px-4"
                            onClick={() => {
                              setTutorialModal(true);
                              setTutorialFile(rowData.video);
                            }}
                          >
                            View Video
                          </button>
                        )
                      }   
                  ]}
                  actions={[
                    {
                      icon: () => <BiCheckboxChecked />,
                      tooltip: 'Mark as Done',
                    //   onClick: (event, rowData) => {
                    //     setRowData(rowData)
                    //     setTutorialFile(rowData.notes)
                    //     setTutorialModal(true)
                    //   },
                    },
                    
                  ]}
                  // isLoading={isCustomerOrderDetailsLoading}
                  data={courseData}
                />
                </div>
              </div>
            </div>
          </div>
    </section>
  </>
  )
}
