import axios from 'axios'
import React, { useState, useEffect } from 'react'
import Config from '../../config/config'
function Dashboard() {
  const [dashboardStats, setDashboardStats]=useState([])
  const config = Config()
  useEffect(() => {
    getDashboardStats()
  }, [])
  const userID = localStorage.userID

  const getDashboardStats = () => {
    const url = `${config.base_url}instructor_dashboard_stats/${userID}`;
    axios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          setDashboardStats(res.data);
        } else if (res.status === 401) {
          window.location.href = '/';
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          window.location.href = '/';
        }
      });
  };
  return (
  <>

  <div class="pagetitle">
    <h1>Dashboard</h1>
    <nav>
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/">Home</a></li>
        <li class="breadcrumb-item active">Instructor</li>
      </ol>
    </nav>
  </div>
  <section className="section dashboard">
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-xxl-3 col-md-6">
            <div className="card info-card sales-card">
  
              <div className="card-body">
                <h5 className="card-title">Students <span></span></h5>
                <div className="d-flex align-items-center">
                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i className="bi bi-people"></i>
                  </div>
                  <div className="ps-3">
                    <h6>{dashboardStats.learner}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xxl-3 col-md-6">
            <div className="card info-card revenue-card">
              <div className="card-body">
                <h5 className="card-title">Assignments </h5>

                <div className="d-flex align-items-center">
                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i className="bi bi-cart"></i>
                  </div>
                  <div className="ps-3">
                    <h6>{dashboardStats.course}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-3 col-md-6">
            <div className="card info-card revenue-card">
              <div className="card-body">
                <h5 className="card-title">Courses </h5>

                <div className="d-flex align-items-center">
                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i className="bi bi-cart"></i>
                  </div>
                  <div className="ps-3">
                    <h6>{dashboardStats.course}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xxl-3 col-md-6">

            <div className="card info-card customers-card">
              <div className="card-body">
                <h5 className="card-title">Quiz </h5>

                <div className="d-flex align-items-center">
                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i className="bi bi-people"></i>
                  </div>
                  <div className="ps-3">
                    <h6>{dashboardStats.instructor}</h6>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="col-xxl-3 col-xl-12">

            <div className="card info-card customers-card">

              <div className="card-body">
                <h5 className="card-title">Tutorials</h5>

                <div className="d-flex align-items-center">
                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i className="bi bi-people"></i>
                  </div>
                  <div className="ps-3">
                    <h6>$1244</h6>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  </>
  )
}

export default Dashboard
