import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Config from '../config/config';

function Header() {
  const config = Config()
  const userID = parseInt(localStorage.userID)
 
  useEffect(() => {
    // getNotifications()
  }, [])
  const getNotifications = () => {
    const url = ` ${config.base_url}view_notifications/?userID=${userID}`;
    axios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
        } else if (res.status === 401) {
          window.location.href = '/';
          const errorMessage = res.response?.data?.message ;
          return errorMessage;
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          window.location.href = '/';
          const errorMessage = error.response?.data?.message || 'Failed to retrieve Courses';
          return errorMessage;
        }
      });
  };
  return (
    <header id="header" className="header fixed-top d-flex align-items-center">

    <div className="d-flex align-items-center justify-content-between">
      <a href="/dashboard" className="logo d-flex align-items-center">
        <img src="assets/img/logo.png" alt=""/>
        {/* <span className="d-none d-lg-block">ACCOUNTS ON CALL</span> */}
      </a>
      <i className="bi bi-list toggle-sidebar-btn"></i>
    </div>

    <nav className="header-nav ms-auto">
      <ul className="d-flex align-items-center">

        <li className="nav-item dropdown pe-3">

          <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
            {/* <img src="assets/img/profile-img.jpg" alt="Profile" className="rounded-circle"/> */}
            <span className="d-none d-md-block dropdown-toggle ps-2">{localStorage.username}</span>
          </a>

          <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
            <li className="dropdown-header">
              <h6>{localStorage.username}</h6>
              <span>{localStorage.userRole}</span>
            </li>
            <li>
              <hr className="dropdown-divider"/>
            </li>

          </ul>
        </li>

      </ul>
    </nav>

  </header>

  )
}

export default Header
