import React from 'react'
import Header from '../../components/Header'
import SideNav from '../../components/SideNav'
import CTAStudents from '../../components/admin/CTAStudents'
function Students() {
  return (
    <>
    <Header/>
    <SideNav/>
    <CTAStudents/>
    </>
  )
}

export default Students
