import React from 'react'
import SideNav from '../../components/SideNav'
import Vacancies from '../../components/admin/JobVacancies'
import Header from '../../components/Header'
function JobVacancies() {
  return (
    <div>
      <Header/>
      <SideNav/>
      <Vacancies/>
    </div>
  )
}

export default JobVacancies
