import React, { useState, useEffect } from 'react'
import {Modal, Form, Spin} from 'antd'
import Config from '../config/config'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Login() {
  const [isLoginLoading,setIsLoginLoading]=useState(false)
  const [user, setUser]= useState('')
  const [loginLoading, setLoginLoading]= useState(false)
  const config = Config()

  
  const triggerToast = () => {
    toast("Invalid username or password!");
  }

  async function handleLogin(values) {
    setLoginLoading(true)
    const payload = {
      username: values.username,
      password: values.password,
    };
    const url = `${config.base_url}user_login/`;

    axios
      .post(url, payload)
      .then((res) => {
         
        if (res.data.error) {
          setIsLoginLoading(false)
          triggerToast()
        }

        if (res.status === 200) {
          setIsLoginLoading(false)
          const redirectUrl = res.data.redirect;
          const userRole = res.data.user_role;
          const superuser = res.data.superuser;
          const username = res.data.user_details.username;
          const userID = res.data.user_details.id;
 
          if (redirectUrl) {
            setUser(userRole);
            localStorage.setItem('userRole', userRole);
            localStorage.setItem('superuser', superuser);
            localStorage.setItem('username', username);
            localStorage.setItem('userID', userID);
            window.location.href = redirectUrl;
            console.log(userID);
          }
          setIsLoginLoading(false);
        } else if (res.status === 401) {
          setIsLoginLoading(false);
          window.location.href = '/';
          const errorMessage = res.response?.data?.message;
          setIsLoginLoading(false)
          return errorMessage;
          
        }
      })
      .catch((error) => {
        setIsLoginLoading(false)
        if (error.response && error.response.status === 401) {
          window.location.href = '/';
          const errorMessage = error.response?.data?.message || 'Failed login failed';
          setIsLoginLoading(false)
          return errorMessage;
        }
      });
  }
 
  return (
    <>  
    <ToastContainer />
    <main>
      <div class="container">
        <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center  py-4">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                <div class="d-flex justify-content-center py-4">
                  <a href="/" class="logo d-flex align-items-center w-auto">
                  <img src="assets/img/logo.png" alt=""/>
                  </a>
                </div>

                <div class="card mb-3">

                  <div class="card-body">

                    <div class="pt-4 pb-2">
                      <h5 class="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                      <p class="text-center small">Enter your username & password to login</p>
                    </div>
                    <Spin spinning={loginLoading}>
                    <Form class="row g-3 needs-validation" 
                    name="loginForm"
                    onFinish={handleLogin}
                    autoComplete="off"
                      >

                      <div class="col-12">
                        <label for="yourUsername" class="form-label">Username</label>
                        <div class="input-group has-validation">
                          <Form.Item
                            name="username"
                              rules={[
                                {
                                  required: true,
                                  message: 'Enter Username / Reg Number'
                                }
                              ]
                              }>
                          <input type="text" class="form-control" id="yourUsername" />
                          </Form.Item>
                          <div class="invalid-feedback">Please enter your username.</div>
                        </div>
                      </div>

                      <div class="col-12">
                        <label for="yourPassword" class="form-label">Password</label>
                        <Form.Item
                          name="password"
                            rules={[
                              {
                                required: true,
                                message: 'Enter password'
                              }
                            ]
                            }>
                          <input type="password" class="form-control" id="yourPassword" />
                          </Form.Item>
                        <div class="invalid-feedback">Please enter your password!</div>
                      </div>
                      <div class="col-12">
                        <button class="btn btn-primary w-100" type="submit">Login</button>
                      </div>
                    </Form>
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      </main>

    </>
  )
}

export default Login
