// import { getLocalStorage } from '@/utils'

const apiVersion = '/api/'
const mediaPath = '/media/'
const url =
  process.env.NODE_ENV === 'production'
    ? ' https://application.accsoncall.com'
    : ' https://application.accsoncall.com'
   
const baseUrl = url + apiVersion
const mediaUrl =url + mediaPath
// const accessToken = getLocalStorage('userInfo')?.accessToken
// const chatAccessToken = getLocalStorage('chatAccessToken')

// const mapApiKey = 'AIzaSyDDMQWv5eTA_-ELUip6MLFppythTofgQMs'
// tokenValidation(accessToken)

export default function Config() {
  const config = {
    base_url: baseUrl,
    main_url: url,
    media_url: mediaUrl
    // mapApiKey: mapApiKey,
    // token: accessToken,
    // chatAccessToken,
  }
  return config
}

// export function tokenValidation(accessToken) {
//   const parseJwt = (token) => {
//     try {
//       return JSON.parse(atob(token.split('.')[1]))
//     } catch (e) {
//       return null
//     }
//   }

//   const decodedJwt = parseJwt(accessToken)
//   if (decodedJwt) {
//     if (decodedJwt.exp * 1000 < Date.now()) {
//       localStorage.clear()
//     }
//   }

//   return null
// }
