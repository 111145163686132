import React from 'react'
import Header from '../../components/Header'
import SideNav from '../../components/SideNav'
import AllMessages from '../../components/admin/Messages'
function Messages() {
  return (
    <div>
      <Header/>
      <SideNav/>
      <AllMessages/>
    </div>
  )
}

export default Messages
