import React from 'react'
import SideNav from '../../components/SideNav'
import MainDashboard from '../../components/admin/Dashboard'
import Header from '../../components/Header'

export default function Dashboard() {
  return (
    <>
    <Header/>
    <SideNav/>
    <MainDashboard/>
    </>
  )
}
