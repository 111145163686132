import React, { useState, useEffect } from 'react'
import {Modal, Form, Spin, Button, Tooltip} from 'antd'
import Config from '../../config/config'
import axios from 'axios'
import { BsFillFileEarmarkPdfFill } from 'react-icons/bs'
export default function Resources() {
  
  const [resources, setResources]=useState([])
  const config = Config()
  useEffect(() => {
    getResources()
  }, [])
  const userID = localStorage.userID
  const getResources = () => {
    const url = ` ${config.base_url}view_resources/`;
    axios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          setResources(res.data);
        } else if (res.status === 401) {
          window.location.href = '/';
          const errorMessage = res.response?.data?.message ;
          return errorMessage;
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          window.location.href = '/';
          const errorMessage = error.response?.data?.message || 'Failed to retrieve Resources';
          return errorMessage;
        }
      });
  };

  return (
    <>

    <div className="pagetitle">
      <h1>Course</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/">Home</a></li>
          <li className="breadcrumb-item">Student</li>
          <li className="breadcrumb-item active">Resources</li>
        </ol>
      </nav>
    </div>
    <section className="section dashboard">
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
        {resources?.map((resource) => (
          <div
            key={resource.id}
            className="col-xxl-3 col-6 col-md-4"
          >
            <a
              href={`${config.main_url}${resource.attachment}`}
              target="_blank"
              rel="noopener noreferrer"
            >
            <div className="card info-card customers-card">
              <div className="card-body">
                <h5 className="card-title"></h5>

                <div className="d-flex align-items-center">
                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <BsFillFileEarmarkPdfFill />
                  </div>
                  <div className="ps-3">
                    <h6>{resource.title}</h6>
                  </div>
                </div>
              </div>
            </div>
            </a>
          </div>
        ))}
      </div>
      </div>
    </div>
  </section>
  </>
  )
}
